import { FormControlLabel } from '@material-ui/core';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import VideoPlayer from 'react-video-js-player';
import { rotate360 } from '../../utils';
import landingPageBackground from '../../images/landingPageBackground.jpg';
import me from '../../images/vania.jpeg';
import sectionBackgroundImage from '../../images/sectionBackgroundImage.jpeg';

export const LandingPageContainer = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const StyledVideoPlayer = styled(VideoPlayer)`
  width: 100%;
  height: 25vh;
`;

export const PageContentOverlay = styled.div`
  background-color: rgb(30, 30, 30);
  bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -2;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin: auto;
`;

export const LandingPageContent = styled.div`
    margin: 0 auto;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 768px) {
    max-width: 100% ;
  }
`;

export const BackgroudImage = styled.div`
background-color: rgb(30, 30, 30);
    opacity: 0.43;
    background-image: url(${landingPageBackground});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
`;

export const LandingPageContentContainer = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 125px 125px 50px 50px;

    .MuiPaper-root {
      background: transparent;
      box-shadow: none;
      ul {
        margin-right: 15px;
        margin-left: 15px;
        background: white;
      }
    }

    @media (max-width: 768px) {
    max-width: 100% ;
    padding: 0;
  }
`;

export const Spinner = styled.div`
    display: inline-block;
    top: 50%;
    right: 50%;
    width: 20px;
    height: 20px;
    border: 3px solid rgba(186, 224, 252, .3);
    border-radius: 50%;
    border-top-color: #333999;
    animation:  ${rotate360}  1s ease-in-out infinite;
    -webkit-animation:  ${rotate360}  1s ease-in-out infinite;
    margin-left: 50%;
`;

export const HeaderCenter = styled.h1`
align-self: center;
justify-self: center;
`;

export const Heading = styled.h1`
/* color: #a47844;  */
color: #e4c584;
text-align: center;
font-family: 'Raleway', sans-serif;
outline: none;
white-space: pre-wrap;
word-break: break-word;
overflow-wrap: break-word;
color: rgb(228, 197, 132);
font-weight: 700;
letter-spacing: 2px;
font-size: 1rem;
`;

export const SubHeading = styled.h2`
outline: none;
color: white;
text-align: center;
font-family: 'Anton', sans-serif;
color: rgb(241, 240, 234);
text-transform: uppercase;
font-size: 3em;
`;

export const Description = styled.p`
color: #f1f0ea;
text-align: center;
font-family: 'Lato', sans-serif;
font-size: 24px;
`;

export const DemoButton = styled.a`
text-transform: uppercase;
background-color: rgb(84, 103, 91);
color: rgb(241, 240, 234);
border-radius: 0px;
font-weight: 700;
text-decoration: none ;
font-family: 'Lato', sans-serif;
border-color: transparent;
    cursor: pointer;
    text-align: center;
    font-size: 1rem;
    -webkit-letter-spacing: 2px;
    -moz-letter-spacing: 2px;
    -ms-letter-spacing: 2px;
    letter-spacing: 2px;
    margin: 0 auto;
    padding-bottom: 22px;
    padding-top: 22px;
    width: 100%;
    margin-top: 32px;
    margin-bottom: 32px;

    &:hover {
      background-color: rgb(84, 103, 91);
      transform: scale(1.02);
    -webkit-transition: ease 300ms transform;
    -webkit-transition: ease 300ms -webkit-transform;
    -webkit-transition: ease 300ms transform;
    transition: ease 300ms transform;
    }

    @media (max-width: 768px) {
   margin-top: 0;
   margin-bottom: 0;
  }
`;

export const DownArrow = styled(Button)`
    background-color: rgb(164, 120, 68);
    color: rgb(30, 30, 30);
    border-radius: 50%;
    min-height: 40px;
    min-width: 40px;
    margin-right: 16px;
    &:hover {
      background-color: rgb(164, 120, 68);
    }
`;

export const ViewMoreButton = styled.a`
cursor: pointer;
text-align: center;
text-decoration: none;
padding-bottom: 32px;
@media (max-width: 768px) {
   display: none;
  }
`;

export const ReadMoreButton = styled(Button)`
cursor: pointer;
  background-color: transparent ;
    color: #f1f0ea;
`;

export const ReadMoreSection = styled.div`
display: flex;
@media (max-width: 768px) {
    min-width: 100%;
    flex-direction: column;
  }
`;

export const Section = styled.div`
background-color: rgb(241, 240, 234);
padding: 125px 120px 125px 50px;
position:relative ;
flex-grow: 1;
@media (max-width: 768px) {
    min-width: 100%;
    padding: 0px;
  }
`;

export const SectionImageOverlay = styled.div`
    background-color: rgb(30, 30, 30);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -2;
`;
export const SectionImageSection = styled.div`
  min-width: 50%;
  position: relative;
  height: auto;
`;

export const SectionImage = styled.div`
    opacity: 1;
    background-image: url(${sectionBackgroundImage});
    background-color: rgb(30, 30, 30);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
`;

export const SectionContentContainer = styled.div`
    margin-left: auto;
    max-width: 440px;
    margin-right: auto;
    @media (max-width: 768px) {
   padding: 20px;
  }
`;

export const SectionSubtitle = styled.h1`
    white-space: pre-wrap;
    overflow-wrap: break-word;
    color: rgb(0, 0, 0);
    line-height: 1.5;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 18px;
`;

export const SectionDescription = styled.p`
outline: none;
white-space: pre-wrap;
word-wrap: break-word;
color: rgb(0, 0, 0);
font-size: 18px;
`;

export const SignUpSection = styled.div`
display: flex;
@media (max-width: 768px) {
    min-width: 100%;
    flex-direction: column;
  }
`;

export const SectionHeader = styled.h2`
    outline: none;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    color: rgb(84, 103, 91);
    font-size: 34.5px;
    font-family: 'Anton', sans-serif;
    line-height: 1.5;
`;

export const FormSection = styled.div`
background-color: rgb(22, 32, 0);
padding: 125px 120px 125px 50px;
position:relative ;
flex-grow: 1;
@media (max-width: 768px) {
    min-width: 100%;
   padding: 0;
  }
`;
export const FormContent = styled.div`
  .formkit-header h1 {
    outline: none;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    color: #e4c484;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 48px;
    font-family: 'Anton', sans-serif;
    word-break: break-word;
  }

  form, .formkit-column {
    background-color:rgb(22,32,0) !important
  }
  .formkit-form.pine {
    box-shadow: none;
    background-color:rgb(22,32,0) !important
  }
  .formkit-form.pine .formkit-submit {
    background-color: #a47843 !important;
    color: #f1f0ea !important;
    border-radius: 0 !important;
    padding: 15px;
  }

  .formkit-field .formkit-input {
    background-color: rgb(22, 32, 0);
    border: 1px solid #f1f0ea ;
    color: #f1f0ea !important;
    border-radius: 0;
    padding: 15px;
  }
  .formkit-form.pine .formkit-powered-by-convertkit {
    display: none !important;
  }
  .formkit-form.pine .formkit-guarantee {
    color: #f1f0ea !important;
  }

  .formkit-subheader {
    display: none;
  }

  .formkit-form.pine .formkit-column {
    padding: 0;
  }

  .formkit-form.pine .formkit-column:nth-child(2) {
    border: 0;
  }
`;

export const Testimonial = styled.div`
display: flex ;
`;

export const TestimonialImage = styled.div`
width: 56px;
height: 56px;
background-image: url(${me});
background-position: center center;
background-repeat: no-repeat;
background-size: cover;
border-radius: 50% ;
align-self: center;

`;

export const TestimonialContent = styled.div`
align-self: center;
margin-left: 15px;

p:first-child {
  font-weight: 700;

}
`;
