import React from 'react';
import { Link } from 'react-router-dom';
import { StyledFooter } from './style';

export default function Footer() {
  return (
    <StyledFooter>
      <Link to="/contact-us"> Contact Us </Link>
      <Link to="/payments"> Payments </Link>
    </StyledFooter>
  );
}
