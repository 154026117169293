import { IconButton } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import MUIPlacesAutocomplete from 'mui-places-autocomplete';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  AutoCompleteResults,
  ErrorMessage,
  InputContainer,
  Spinner,
} from '../style';

class MLSDeals extends Component {
  constructor() {
    super();
    this.state = {
      address: '',
      error: '',
    };
  }

  setAddress = (ev, value) => {
    const address = value || ev.target.value;
    this.setState({ address });
  };

  componentDidUpdate = (prevProps) => {
    const { userLogin: { propertyAddress } } = this.props;
    const prevAddress = Object.keys(prevProps.userLogin.propertyAddress).length;
    const currentAddress = Object.keys(propertyAddress).length;
    if (prevAddress !== currentAddress) {
      prevProps.onClick(propertyAddress, () => {
        this.setState({ error: '' });
      }, true);
    }
  };

  searchTriggered = () => {
    const { address } = this.state;
    const { setAddress, onClick } = this.props;

    if (address.length) {
      setAddress(address);
      this.setState({ error: '' });
      const { userLogin: { propertyAddress } } = this.props;
      if (Object.keys(propertyAddress).length) {
        onClick(propertyAddress, () => {
          this.setState({ error: '' });
        }, true);
      }
    }
  };

  onSuggestionSelected = (results) => {
    const { description: address } = results;
    this.setState({ address });
  };

  render() {
    let { error } = this.state;
    const {
      userLogin: {
        unableToFetchAddress,
      },
      fetchingPropertyDetails,
    } = this.props;

    if (unableToFetchAddress) {
      error = 'Unable to fetch property details, try again';
    }

    const inputAdornment = (
      <IconButton
        aria-label="search"
        onClick={this.searchTriggered}
        onMouseDown={() => { }}
        edge="end"
      >
        {fetchingPropertyDetails ? <Spinner /> : <SearchIcon /> }
      </IconButton>
    );

    const { address } = this.state;
    const value = address;
    const onChange = this.setAddress;
    const hasError = !!error.length;

    return (
      <>
        <InputContainer>
          <MUIPlacesAutocomplete
            onChange={onChange}
            key="address"
            textFieldProps={{
              value,
              placeholder: 'Search for address',
              onChange: (e) => onChange(e),
              variant: 'outlined',
            }}
            onSuggestionSelected={this.onSuggestionSelected}
            renderTarget={() => (<AutoCompleteResults />)}
            createAutocompleteRequest={(inputValue) => ({
              input: inputValue,
              types: ['address'],
              componentRestrictions: { country: 'us' },
            })}
          />
          {inputAdornment}
        </InputContainer>
        {hasError ? (
          <ErrorMessage>
            {' '}
            {error}
            {' '}
          </ErrorMessage>
        ) : null}
      </>
    );
  }
}

MLSDeals.propTypes = {
  setAddress: PropTypes.func.isRequired,
  userLogin: PropTypes.shape({
    invalidCode: PropTypes.bool,
    unableToFetchAddress: PropTypes.bool,
    propertyAddress: PropTypes.shape({}),
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  fetchingPropertyDetails: PropTypes.bool.isRequired,
};

export default MLSDeals;
