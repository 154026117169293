import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';

export const StyledCloseIcon = styled(CloseIcon)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StyledCloseButton = styled(IconButton)`
    float: right !important;
`;
