/* eslint-disable react/jsx-props-no-spreading */
import React, { createRef, Component } from 'react';
import ConvertKitForm from 'convertkit-react';
import PropTypes from 'prop-types';
import {
  Heading,
  DownArrow,
  DemoButton,
  ReadMoreButton,
  ReadMoreSection,
  ViewMoreButton,
  SubHeading,
  Description,
  SectionDescription,
  Section,
  SectionImage,
  SectionImageSection,
  SectionSubtitle,
  SectionImageOverlay,
  LandingPageContainer,
  LandingPageContentContainer,
  PageContentOverlay,
  LandingPageContent,
  BackgroudImage,
  SignUpSection,
  SectionHeader,
  FormSection,
  FormContent,
  SectionContentContainer,
  Testimonial,
  TestimonialImage,
  TestimonialContent,
} from './style';

const MY_FORM_ID = 3249912;

class LandingPage extends Component {
  constructor() {
    super();
    this.readMoreRef = createRef();
    this.config = {
      formId: MY_FORM_ID,
      template: 'pine',
      headingText: 'SETUP YOUR FREE ACCOUNT BELOW',
      emailPlaceholder: 'Email Address',
      submitText: 'SIGN UP TODAY',
      buttonColor: '#e4c484',
    };
  }

  render() {
    return (
      <>
        <LandingPageContainer>
          <PageContentOverlay />
          <BackgroudImage />
          <LandingPageContentContainer>
            <LandingPageContent>
              <Heading> ANALYZE YOUR NEXT REAL ESTATE DEAL </Heading>
              <SubHeading>FIND MY NEXT INVESTMENT</SubHeading>
              <Description>
                Our rental property calculator sources information from site like Zillow,
                Redfin, and the MLS to make it  easier to determine
                cash on cash return on investment, income, expenses, cap rate and more...
              </Description>
              <DemoButton
                href="/demo"
                onClick={() => {
                  const { logAnalyticEvent } = this.props;
                  logAnalyticEvent('demoClicked');
                }}
              >
                TRY IT FREE
              </DemoButton>
              <ViewMoreButton
                onClick={() => {
                  const { logAnalyticEvent } = this.props;
                  logAnalyticEvent('readMoreClicked');
                  return this.readMoreRef.current.scrollIntoView({ behavior: 'smooth' });
                }}
              >
                <DownArrow>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 8L14.59 6.59L9 12.17V0H7V12.17L1.42 6.58L0 8L8 16L16 8Z"
                      fill="currentColor"
                      style={{ backgroundColor: 'rgb(164, 120, 68)', color: 'rgb(30, 30, 30)' }}
                    />
                  </svg>
                </DownArrow>
                <ReadMoreButton> Read more </ReadMoreButton>
              </ViewMoreButton>
            </LandingPageContent>
          </LandingPageContentContainer>
        </LandingPageContainer>
        <ReadMoreSection id="#readMore" ref={this.readMoreRef}>
          <Section>
            <SectionContentContainer>
              <SectionHeader>
                Thinking about buying a new rental property?
                Or just want to check how your existing rental is performing?
              </SectionHeader>
              <SectionSubtitle>
                INTRODUCING FIND MY NEXT INVESTMENT:
              </SectionSubtitle>
              <SectionDescription>
                Our rental calculator accounts for  up-front,
                ongoing costs and not so obvious costs like taxes and insurance.
                Information is  pre-populated into the calculator to make
                those calculations quicker and easier
              </SectionDescription>
            </SectionContentContainer>
          </Section>
          <SectionImageSection>
            <SectionImageOverlay />
            <SectionImage />
          </SectionImageSection>
        </ReadMoreSection>
        <SignUpSection id="#signUp">
          <FormSection>
            <SectionContentContainer>
              <FormContent>
                <ConvertKitForm {...this.config} />
              </FormContent>
            </SectionContentContainer>
          </FormSection>
          <Section>
            <SectionContentContainer>
              <SectionHeader>
                Hi, my name is Vania Nettleford, and I&apos;m a real estate investor.
                I&apos;m so excited to introduce you to Find My Next Investment.
              </SectionHeader>
              <SectionDescription>
                I hope that you find the tool easy to use,
                and I can&apos;t wait to share it with you.
              </SectionDescription>
              <Testimonial>
                <TestimonialImage />
                <TestimonialContent>
                  <p>
                    Vania Nettleford
                  </p>
                  <p>
                    Creator
                  </p>
                </TestimonialContent>
              </Testimonial>
            </SectionContentContainer>
          </Section>
        </SignUpSection>
      </>
    );
  }
}
LandingPage.propTypes = {
  logAnalyticEvent: PropTypes.func.isRequired,
};

export default LandingPage;
