import styled from 'styled-components';
import Paper from '@mui/material/Paper';

import TextField from '@material-ui/core/TextField';

export const StyledTextField = styled(TextField)`
  display: flex;
  margin-bottom: 50px;
`;

export const StyledPaper = styled(Paper)`
  top: 54px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column ;
  align-items: center ;
  background: transparent;
  box-shadow: none;
`;

export const StyledForm = styled.form`
  min-width: 95%;

`;
