import { OutlinedInput, FormControlLabel } from '@material-ui/core';
import styled from 'styled-components';
import VideoPlayer from 'react-video-js-player';
import { rotate360 } from '../../utils';
import demoPageBackground from '../../images/demoPageBackground.jpg';

export const LandingPageContainer = styled.div`
    background-image: url(${demoPageBackground});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 39px !important;
    top: 50px;
    overflow-y: scroll;
`;

export const StyledVideoPlayer = styled(VideoPlayer)`
  width: 100%;
  height: 25vh;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin: auto;
`;

export const LandingPageContentContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
    margin-left: 15px;
    margin-right: 15px;

    .MuiPaper-root {
      background: transparent;
      box-shadow: none;
      ul {
        margin-right: 15px;
        margin-left: 15px;
        background: white;
      }
    }
`;

export const Spinner = styled.div`
    display: inline-block;
    top: 50%;
    right: 50%;
    width: 20px;
    height: 20px;
    border: 3px solid rgba(186, 224, 252, .3);
    border-radius: 50%;
    border-top-color: #333999;
    animation:  ${rotate360}  1s ease-in-out infinite;
    -webkit-animation:  ${rotate360}  1s ease-in-out infinite;
    margin-left: 50%;
`;

export const HeaderCenter = styled.h1`
align-self: center;
justify-self: center;
`;

export const ErrorMessage = styled.p`
    margin: 0;
    color: #f44336;
`;

export const StyledOutlinedInput = styled(OutlinedInput)`
.MuiInputBase-input {
    color: #3E4139;
}
`;

export const InputContainer = styled.div`
display: flex;
flex-direction: row;
div {
    width: 100%;
    .MuiFormControl-root {
        margin-top: 6px;
    }
}
`;

export const AutoCompleteResults = styled.div`
`;
