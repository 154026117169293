import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import './App.css';
import CalculatePage from './pages/CalculatePage';
import DemoPage from './pages/Demo';
import { Spinner } from './pages/Payments/style';
import Footer from './components/Footer';

function App({
  addFieldValue,
  addCalculatedResults,
  calculatorFields,
  fetchingPropertyDetails,
  propertyDetails = {},
  propertySummaryFields,
  requestUserLogin,
  resultsFields,
  searchAddress,
  setAddress,
  hideModal,
  showModal,
  userLogin,
  logAnalyticEvent,
  verifyUser,
}) {
  useEffect(() => {
    requestUserLogin();
  }, []);

  if (!userLogin?.isVerified) {
    return (
      <>
        <DemoPage
          fetchingPropertyDetails={fetchingPropertyDetails}
          onClick={verifyUser}
          onSearch={searchAddress}
          setAddress={setAddress}
          userLogin={userLogin}
          logAnalyticEvent={logAnalyticEvent}
        />
        <Footer />
      </>
    );
  } if (!propertyDetails) {
    return <Spinner />;
  }
  return (
    <CalculatePage
      addCalculatedResults={addCalculatedResults}
      addFieldValue={addFieldValue}
      showModal={showModal}
      hideModal={hideModal}
      calculatorFields={calculatorFields}
      propertyDetails={propertyDetails}
      propertySummaryFields={propertySummaryFields}
      resultsFields={resultsFields}
      logAnalyticEvent={logAnalyticEvent}
      userLogin={userLogin}
    />
  );
}

App.propTypes = {
  addFieldValue: PropTypes.func.isRequired,
  addCalculatedResults: PropTypes.func.isRequired,
  calculatorFields: PropTypes.shape({}).isRequired,
  fetchingPropertyDetails: PropTypes.bool.isRequired,
  propertyDetails: PropTypes.shape({}).isRequired,
  propertySummaryFields: PropTypes.shape({}).isRequired,
  requestUserLogin: PropTypes.func.isRequired,
  resultsFields: PropTypes.shape({}).isRequired,
  searchAddress: PropTypes.func.isRequired,
  setAddress: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  userLogin: PropTypes.shape({ isVerified: PropTypes.bool }).isRequired,
  logAnalyticEvent: PropTypes.func.isRequired,
  verifyUser: PropTypes.func.isRequired,
};
export default App;
