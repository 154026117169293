import PropTypes from 'prop-types';
import React from 'react';
import {
  BarContainer,
  Filler,
  FillerBackground,
  ProgressWrapper,
  TextValue,
  Wrapper,
} from './style';

export const ProgressBar = (props) => {
  const { valuePercentage: value, label = 'Unknown', description = '' } = props;
  const fillerRelativePercentage = (100 / value) * 100;

  return (
    <ProgressWrapper>
      <TextValue value={value}>{label}</TextValue>
      <Wrapper
        className="wrapper"
        role="progressbar"
        aria-valuemin={0}
        aria-valuemax={100}
        aria-valuenow={value}
      >
        <BarContainer className="barContainer">
          <Filler className="filler" width={`${value}%`}>
            <FillerBackground
              className="fillerBackground"
              width={`${fillerRelativePercentage}%`}
              value={value}
            />
          </Filler>
        </BarContainer>
      </Wrapper>
      <p>
        ** Disclaimer: This is purely informational based on the information that you have provided
        this is and not and should not be take as investment advice **
      </p>
      <p>
        CAP rate, cash flow, and ROI are great starting points to help you pinpoint
        outstanding investment properties
      </p>
      <p>
        {description}
      </p>
    </ProgressWrapper>
  );
};

export const ProgressBarFlexible = (props) => {
  const { valuePercentage: value } = props;
  const fillerRelativePercentage = (100 / value) * 100;
  const fillerFlex = value > 0 ? value / 100 : 0;

  return (
    <div
      className="wrapper"
      role="progressbar"
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuenow={value}
    >
      <div className="barContainer" style={{ flex: fillerFlex }}>
        <div
          className="fillerBackground"
          style={{ width: `${fillerRelativePercentage}%` }}
        />
      </div>
      <div className="textValue">{`${value}%`}</div>
    </div>
  );
};

ProgressBar.defaultProps = {
  valuePercentage: 0,
  label: '',
  description: '',
};

ProgressBar.propTypes = {
  valuePercentage: PropTypes.number,
  label: PropTypes.string,
  description: PropTypes.string,
};

ProgressBarFlexible.defaultProps = {
  valuePercentage: 0,
};

ProgressBarFlexible.propTypes = {
  valuePercentage: PropTypes.number,
};
export default ProgressBar;
