const reducer = (state, action) => {
  const { payload = {} } = action;
  const { key, value } = payload;
  switch (action.type) {
    case 'SHOW_MODAL': {
      return {
        ...state,
        modal: {
          ...state.modal,
          shouldShouldModal: true,
          content: payload.content,
          title: payload.title,
        },
      };
    }
    case 'HIDE_MODAL': {
      return {
        ...state,
        modal: {
          content: null,
          title: null,
          shouldShouldModal: false,
        },
      };
    }
    case 'FILL_SUMMARY_FIELD': {
      return {
        ...state,
        propertySummaryFields: {
          ...state.propertySummaryFields,
          [key]: {
            ...state.propertySummaryFields[key],
            value,
          },
        },
      };
    }

    case 'ADD_CALCULATED_FIELD':
      return {
        ...state,
        resultsFields: {
          ...state.resultsFields,
          [key]: {
            ...state.resultsFields[key],
            value,
          },
        },
      };
    case 'ADD_FIELD': {
      let updatedValue = value;
      if (state.canBeEmpty && !value) {
        updatedValue = ' ';
      }
      return {
        ...state,
        calculatorFields: {
          ...state.calculatorFields,
          [key]: {
            ...state.calculatorFields[key],
            value: updatedValue,
          },
        },
      };
    }
    case 'REMOVE_FIELD': {
      const updatedFields = state.calculatorFields;
      delete updatedFields[payload];
      return {
        ...state,
        calculatorFields: updatedFields,
      };
    }
    case 'VERIFY_USER': {
      return {
        ...state,
        userLogin: {
          ...state.userLogin,
          isVerified: value,
          invalidCode: !value,
          unableToFetchAddress: payload.unableToFetchAddress,
        },
        fetchingPropertyDetails: false,
      };
    }
    case 'SET_ADDRESS': {
      return {
        ...state,
        userLogin: {
          ...state.userLogin,
          propertyAddress: value,
          unableToFetchAddress: false,
        },
        fetchingPropertyDetails: false,
      };
    }
    case 'USER_LOGIN': {
      return {
        ...state,
        userLogin: {
          ...state.userLogin,
          user: value,
        },
      };
    }
    case 'FETCH_PROPERTY_DETAILS': {
      return {
        ...state,
        propertyDetails: value,
        fetchingPropertyDetails: false,
      };
    }
    case 'FETCHING_PROPERTY_DETAILS': {
      return {
        ...state,
        fetchingPropertyDetails: true,
      };
    }
    default:
      return state;
  }
};

export default reducer;
