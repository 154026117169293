import styled from 'styled-components';

export const StyledFooter = styled.div`
 position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex ;
  justify-content: center ;
  background: #b6dad8;

  a {
    color: #004113;
    margin: 10px;
    text-decoration: none;
  }
`;
