import styled from 'styled-components';
import { ButtonSizes } from './sizes';

export const WindowBackground = styled.div`
  height: 100%;
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 74px;
  button {
      height: ${ButtonSizes.heightSmall};
      border-radius: calc(${ButtonSizes.heightSmall}/2);
      min-width: ${ButtonSizes.widthSmall}
  }
`;
