import styled from 'styled-components';

const rangeColor = (value) => {
  if (!value || value <= 25) { return '#e5405e'; }
  if (value <= 60) { return '#ffdb3a'; }
  if (value <= 100) { return '#3fffa2'; }
  return '#3fffa2';
};

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const ProgressWrapper = styled.div`
  margin-bottom: 20px;
  padding-right: 5vw;
  padding-left: 5vw;
`;

export const BarContainer = styled.div`
  flex: 1;
  height: 8px;
  background: #dfdfe7;
  border-radius: 12px;
  overflow: hidden;
`;

export const FillerBackground = styled.div`
  height: inherit;
  transition: "width 2s ease-i-out";
  background: ${(props) => rangeColor(props.value)};
  width: ${(props) => props.width}
`;

export const Filler = styled.div`
  transition: "width 2s ease-i-out";
  height: inherit;
  border-radius: inherit;
  overflow: hidden;
  width: ${(props) => props.width}
`;

export const TextValue = styled.div`
font-weight: bold ;
margin-left: 8px;
text-align: left;
width: 100px;
margin-left: ${(props) => `${props.value}%`};

`;
