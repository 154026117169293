import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Contact from 'pages/ContactUs';
import LandingPage from 'pages/LandingPage';
import App from './App';
import BasicModal from './components/BasicModal';
import { GlobalContext, GlobalProvider } from './context/GlobalState';
import './index.css';
import Navbar from './pages/Navigation';
import PageNotFound from './pages/PageNotFound';
import PaymentsPage from './pages/Payments';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <GlobalProvider>
        <GlobalContext.Consumer>
          {
            ({
              addFieldValue,
              addCalculatedResults,
              calculatorFields,
              fetchingPropertyDetails,
              showModal,
              hideModal,
              propertyDetails = {},
              propertySummaryFields,
              requestUserLogin,
              resultsFields,
              searchAddress,
              setAddress,
              modal,
              logAnalyticEvent,
              updateAccessCode,
              userLogin,
              verifyUser,
            }) => (
              <>
                <BasicModal
                  shouldShouldModal={modal.shouldShouldModal}
                  hideModal={hideModal}
                  title={modal.title}
                  content={modal.content}
                />
                <Navbar userLogin={userLogin} />
                <Routes>
                  <Route
                    path="/"
                    element={(
                      <LandingPage logAnalyticEvent={logAnalyticEvent} />
                    )}
                  />
                  <Route
                    path="/demo"
                    element={(
                      <App
                        fetchingPropertyDetails={fetchingPropertyDetails}
                        addCalculatedResults={addCalculatedResults}
                        addFieldValue={addFieldValue}
                        calculatorFields={calculatorFields}
                        hideModal={hideModal}
                        showModal={showModal}
                        propertyDetails={propertyDetails || {}}
                        propertySummaryFields={propertySummaryFields}
                        resultsFields={resultsFields}
                        requestUserLogin={requestUserLogin}
                        searchAddress={searchAddress}
                        setAddress={setAddress}
                        userLogin={userLogin}
                        verifyUser={verifyUser}
                        logAnalyticEvent={logAnalyticEvent}
                      />
                    )}
                  />
                  <Route
                    path="/payments"
                    element={(
                      <PaymentsPage
                        logAnalyticEvent={logAnalyticEvent}
                        propertyDetails={propertyDetails}
                        showModal={showModal}
                        hideModal={hideModal}
                        updateAccessCode={updateAccessCode}
                        requestUserLogin={requestUserLogin}
                        userLogin={userLogin}
                      />
                    )}
                  />
                  <Route
                    path="/contact-us"
                    element={(
                      <Contact />
                    )}
                  />
                  <Route path="*" element={<PageNotFound />} />
                </Routes>
              </>
            )
          }
        </GlobalContext.Consumer>
      </GlobalProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
