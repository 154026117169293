/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { logAnalyticEvent } from '../../api/firebase';
import {
  HeaderCenter,
  LandingPageContainer,
  LandingPageContentContainer,
  StyledVideoPlayer,
} from './style';

import DemoVideo from '../../images/demo_video.mp4';
import OffMarketDeals from './OffMarketDeals';

class LandingPage extends Component {
  render() {
    const landingPagePrompt = (
      <>
        <HeaderCenter> Is this a great investment? </HeaderCenter>
        <div>
          <StyledVideoPlayer
            controls
            src={DemoVideo}
            width="100%"
            height="25%"
            onPlay={(duration) => {
              logAnalyticEvent('demo_video_played', {
                duration,
              });
            }}
          />
        </div>
        <br />
        <br />
        <p> Get started by entering the property address to analyze your potential investment.</p>
      </>
    );

    return (
      <LandingPageContainer>
        <LandingPageContentContainer>
          {landingPagePrompt}
          <OffMarketDeals
            {...this.props}
            searchTriggered={this.searchTriggered}
          />
        </LandingPageContentContainer>
      </LandingPageContainer>
    );
  }
}

LandingPage.propTypes = {
  onSearch: PropTypes.func.isRequired,
  setAddress: PropTypes.func.isRequired,
  userLogin: PropTypes.shape({
    propertyAddress: PropTypes.shape({}),
    invalidCode: PropTypes.bool,
    unableToFetchAddress: PropTypes.bool,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  fetchingPropertyDetails: PropTypes.bool.isRequired,
};

export default LandingPage;
