/* eslint-disable max-len */
import CardContent from '@mui/material/CardContent';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import PaypalButtonWrapper from './PaypalButtonWrapper';
import FooterComponent from '../../components/Footer';
import {
  DiscountContainer,
  PaymentConfirmationContainer,
  PaymentsContainer,
  StyledBox,
  StyledButton,
  StyledCard,
  StyledCardActions,
  StyledContent,
  StyledImage,
  StyledLinkButton,
  StyledPaper,
  StyledPrice,
} from './style';
import BeginnerImage from '../../images/beginner.png';
import IntermediateImage from '../../images/intermediate.png';
import ExpertImage from '../../images/expert.png';
import { logAnalyticEvent } from '../../api/firebase';

class PaymentsPage extends Component {
  componentDidMount() {
    const { requestUserLogin } = this.props;
    requestUserLogin();
    logAnalyticEvent('visitPurchasePage');

    this.paymentOptions = [
      {
        title: 'Beginner',
        buttonText: 'Buy Now',
        price: '$5',
        content: 'Get 6 access codes',
        image: BeginnerImage,
        accessCodeCount: 6,

      },
      {
        title: 'Intermediate',
        buttonText: 'Buy Now',
        price: '$15',
        content: 'Get 21 access codes',
        image: IntermediateImage,
        hasDiscount: true,
        discountAmount: '15%',
        accessCodeCount: 21,
      },
      {
        title: 'Expert',
        buttonText: 'Buy Now',
        price: '$50',
        content: 'Get 75 access codes',
        image: ExpertImage,
        hasDiscount: true,
        discountAmount: '20%',
        accessCodeCount: 75,
      },
    ];
  }

  selectPaymentChoice = (title, amount, accessCodeCount) => {
    const getInitialPaymentOptions = () => ({
      'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
      currency: 'USD',
      intent: 'capture',
      components: 'buttons',
    });
    const options = getInitialPaymentOptions();
    const { currency } = options;
    const [, value] = amount.split('$');

    const { hideModal, showModal, updateAccessCode } = this.props;

    const modalContent = (
      <>
        <PaymentConfirmationContainer>
          <span>
            { `You are about to purchase the ${title} package for a one time charge of ${amount}. Great choice! You'll receive ${accessCodeCount} access codes.`}
            <StyledLinkButton variant="text" onClick={hideModal}> click here to change selection </StyledLinkButton>
          </span>
        </PaymentConfirmationContainer>
        <PayPalScriptProvider options={options} deferLoading>
          <PaypalButtonWrapper
            currency={currency}
            hideModal={hideModal}
            value={value}
            updateAccessCode={updateAccessCode}
            accessCodeCount={accessCodeCount}
          />
        </PayPalScriptProvider>
      </>
    );

    showModal(modalContent, 'Payment Methods');
  };

  render() {
    const paymentOptions = this.paymentOptions || [];
    return (
      <PaymentsContainer>
        <p>
          {' '}
          <StyledContent> Affordable simple pricing </StyledContent>
        </p>
        <h1>Just make a choice </h1>
        <p> Purchase access codes that can be used to unlock a rental analysis. Access codes can only be used once </p>
        <StyledBox sx={{
          display: 'flex',
          flexWrap: 'wrap',
          '& > :not(style)': {
            m: 1,
          },
        }}
        >
          {
            paymentOptions.map(({
              title,
              buttonText,
              price,
              content,
              image,
              hasDiscount,
              discountAmount,
              accessCodeCount,
            }) => (
              <StyledPaper key={title} elevation={3} sx={{ width: '25%' }} className="color-paper">
                <StyledCard sx={{ height: '100%' }} className="color-card">
                  <CardContent>
                    <StyledImage src={image} />
                    {' '}
                    <br />
                    {title}
                    {' '}
                    <br />
                    <br />
                    <StyledPrice>
                      {price}
                      {' '}
                    </StyledPrice>
                    <br />
                    <br />
                    <StyledContent>{content}</StyledContent>
                  </CardContent>
                  <StyledCardActions>
                    <StyledButton size="small" onClick={() => this.selectPaymentChoice(title, price, accessCodeCount)}>{buttonText}</StyledButton>
                  </StyledCardActions>
                </StyledCard>
                {hasDiscount ? (
                  <DiscountContainer>
                    {' '}
                    {discountAmount}
                  </DiscountContainer>
                ) : null}
              </StyledPaper>
            ))
            }
        </StyledBox>
        <FooterComponent />
      </PaymentsContainer>
    );
  }
}

PaymentsPage.propTypes = {
  requestUserLogin: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  updateAccessCode: PropTypes.func.isRequired,
};

export default PaymentsPage;
