import { List } from '@material-ui/core';
import styled from 'styled-components';

export const StyledList = styled(List)`
    display: flex;
    flex-direction: ${(props) => (props.isVertical ? 'column' : 'row')};
    flex-wrap: wrap;

    li {
      width: auto !important;
    }
`;
