import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { StyledCloseButton, StyledCloseIcon } from './styles';

export default function BasicModal({
  shouldShouldModal, hideModal, title, content,
}) {
  return (
    <div>
      <Dialog
        open={shouldShouldModal}
        onClose={hideModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        scroll="paper"
        fullWidth
      >
        <div>
          <StyledCloseButton onClick={hideModal}>
            <StyledCloseIcon className="float-right" />
          </StyledCloseButton>
          <DialogTitle disableTypography>
            <Typography id="modal-modal-title" variant="h6" component="h2" align="center">
              {title}
            </Typography>
            <hr />
          </DialogTitle>

          <DialogContent>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {content}
            </Typography>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}

BasicModal.defaultProps = {
  shouldShouldModal: false,
  title: '',
  content: null,
};

BasicModal.propTypes = {
  shouldShouldModal: PropTypes.bool,
  hideModal: PropTypes.func.isRequired,
  title: PropTypes.string,
  content: PropTypes.node,
};
