import {
  PayPalButtons,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import emailjs from '@emailjs/browser';
import { Spinner } from './style';

const style = {
  layout: 'vertical',
  color: 'gold',
  shape: 'pill',
  label: 'paypal',
};
const ButtonWrapper = ({
  currency,
  value,
  hideModal,
  updateAccessCode,
  accessCodeCount,
}) => {
  const [{ options, isResolved }, dispatch] = usePayPalScriptReducer();

  useEffect(() => {
    dispatch({
      type: 'resetOptions',
      value: {
        ...options,
        currency,
      },
    });
  }, [currency]);

  return (
    <>
      {!isResolved ? <Spinner />
        : (
          <PayPalButtons
            style={style}
            createOrder={(data, actions) => actions.order
              .create({
                purchase_units: [
                  {
                    amount: {
                      currency_code: currency,
                      value,
                    },
                  },
                ],
              })
              .then((orderId) => orderId)}
            onApprove={(data, actions) => actions.order.capture().then((details) => {
              // Your code here after capture the order
              const { orderID } = data;
              const { payer } = details;
              const { email_address: email, name: { given_name: givenName, surname } } = payer;
              // check amount and save emails and amount of attempts
              updateAccessCode(orderID,
                accessCodeCount,
                email,
                givenName,
                surname,
                currency,
                value);
              try {
                emailjs.send(
                  process.env.REACT_APP_SENDJS_SERVICE_ID,
                  process.env.REACT_APP_SENDJS_ACCESS_CODE_TEMPLATE_ID,
                  { access_code: orderID },
                  process.env.REACT_APP_SENDJS_USER_ID,
                )
                  .then(() => {
                    hideModal();
                  }, () => {});
              } catch {
                // I want application to not crush, but don't care about the message
              }
            })}
            onError={(error) => {
              // eslint-disable-next-line no-console
              console.log(error);
              hideModal();
            }}
          />
        )}
    </>
  );
};

ButtonWrapper.propTypes = {
  currency: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  hideModal: PropTypes.func.isRequired,
  updateAccessCode: PropTypes.func.isRequired,
  accessCodeCount: PropTypes.number.isRequired,
};

export default ButtonWrapper;
