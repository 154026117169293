/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-eval */
import { ListItem } from '@material-ui/core';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CommuteIcon from '@mui/icons-material/Commute';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import HouseIcon from '@mui/icons-material/House';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PercentIcon from '@mui/icons-material/Percent';
import RepeatIcon from '@mui/icons-material/Repeat';
import SavingsIcon from '@mui/icons-material/Savings';
import Insights from '@mui/icons-material/Insights';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import InputField from '../../components/InputField';
import ListGroup from '../../components/List';
import Rating from '../../components/Feedback';
import { ProgressBar } from '../../components/ProgressBar';
import HorizontalLabelPositionBelowStepper from '../../components/Stepper';
import CashOnCashReturnIcon from '../../images/CashOnCashReturn.svg';
import ExpenseIcon from '../../images/ExpenseIcon.svg';
import NetIncomeIcon from '../../images/NetIncomeIcon.svg';
import PlaceHolderHouseImage from '../../images/placeholder-house.svg';
import { WindowBackground } from '../../theme/default';
import {
  pmt, cashOnCashROI, capRate, substituteKeyWithCalculatedValueFromFormula,
} from '../../utils';
import {
  CalculationsContainer,
  ColumnFlex,
  DetailedDiv,
  InputFieldsContainer,
  NeighborHoodContainer,
  PropertySummaryContainer,
  ResultsSummaryContainer, StyledBikeRating, StyledDivLeftRightColumn, StyledIcon,
  StyledList,
  StyledSpan,
  StyledText,
  StyledTitle,
  StyledTransitRating,
  StyledWalkRating,
} from './style';

export default function CalculatePage({
  addFieldValue,
  propertySummaryFields,
  logAnalyticEvent,
  calculatorFields,
  propertyDetails,
  resultsFields,
  showModal,
  hideModal,
  addCalculatedResults,
  userLogin,
}) {
  const walkScoreRanges = {
    100: {
      title: 'Walker\'s Paradise',
      description: 'Daily errands do not require a car.',
      rank: 5,
    },
    89: {
      title: 'Very Walkable',
      description: 'Most errands can be accomplished on foot.',
      rank: 4,
    },
    69: {
      title: 'Somewhat Walkable',
      description: 'Most errands can be accomplished on foot.',
      rank: 3,
    },
    49: {
      title: 'Car-Dependent',
      description: 'Most errands can be accomplished on foot.',
      rank: 2,
    },
    24: {
      title: 'Car-Dependent',
      description: 'Almost all errands require a car.',
      rank: 1,
    },
  };

  const bikeScoreRanges = {
    100: {
      title: 'Biker\'s Paradise',
      description: 'Daily errands can be accomplished on a bike.',
      rank: 5,
    },
    89: {
      title: 'Very Bikeable',
      description: 'Biking is convenient for most trips.',
      rank: 4,
    },
    69: {
      title: 'Bikeable',
      description: 'Some bike infrastructure.',
      rank: 3,
    },
    49: {
      title: 'Somewhat Bikeable',
      description: 'Minimal bike infrastructure.',
      rank: 2,
    },
    24: {
      title: 'Somewhat Bikeable',
      description: 'Minimal bike infrastructure.',
      rank: 1,
    },
  };

  const transitScoreRanges = {
    100: {
      title: 'Rider\'s Paradise',
      description: 'World-class public transportation.',
      rank: 5,
    },
    89: {
      title: 'Excellent Transit',
      description: 'Transit is convenient for most trips.',
      rank: 4,
    },
    69: {
      title: 'Good Transit',
      description: 'Many nearby public transportation options',
      rank: 3,
    },
    49: {
      title: 'Some Transit',
      description: 'A few nearby public transportation options.',
      rank: 2,
    },
    24: {
      title: 'Minimal Transit',
      description: 'It is possible to get on a bus.',
      rank: 1,
    },
  };

  const icons = {
    House: <HouseIcon fontSize="large" />,
    Savings: <SavingsIcon fontSize="large" />,
    Money: <AttachMoneyIcon fontSize="large" />,
    Cash: <LocalAtmIcon fontSize="large" />,
    Repeat: <RepeatIcon fontSize="large" />,
    RepeatYearly: <EventRepeatIcon fontSize="large" />,
    Percent: <PercentIcon fontSize="large" />,
    NetIncome: <img src={NetIncomeIcon} alt="net income icon" />,
    Expense: <img src={ExpenseIcon} alt="expense icon" />,
    CashOnCashReturn: <img src={CashOnCashReturnIcon} alt="cash on cash return icon" />,
    Insights: <Insights fontSize="large" />,
  };

  const scoreToRatingLabel = (score = 0, options) => {
    const item = Object.entries(options).find(([maxScore]) => {
      if (typeof score !== 'number') { return false; }
      return score <= maxScore;
    });
    return (item && item[1]) || { title: 'Data Unknown', description: 'Unable to retrieve this data', rank: 0 };
  };

  const getRatings = () => {
    let ratingItem = {};
    return Object.entries(propertySummaryFields).map(([key, listItem]) => {
      if (listItem.type === 'score') {
        switch (key) {
          case 'walkscore':
            ratingItem = scoreToRatingLabel(listItem.value, walkScoreRanges);
            return (
              <ListItem key={key} className="ratingList">
                <StyledSpan>
                  {listItem.label}
                  <br />
                  <span>{ratingItem.title}</span>
                  {' '}

                </StyledSpan>
                <StyledWalkRating
                  value={ratingItem.rank}
                  getLabelText={(value) => `${value} walkscore${value !== 1 ? 's' : ''}`}
                  precision={1}
                  readOnly
                  icon={<DirectionsWalkIcon fontSize="small" />}
                  emptyIcon={<DirectionsWalkIcon fontSize="small" />}
                />
              </ListItem>
            );
          case 'bikescore':
            ratingItem = scoreToRatingLabel(listItem.value, bikeScoreRanges);
            return (
              <ListItem key={key} className="ratingList">
                <StyledSpan>
                  {listItem.label}
                  <br />
                  <span>{ratingItem.title}</span>
                  {' '}

                </StyledSpan>
                <StyledBikeRating
                  value={ratingItem.rank}
                  getLabelText={(value) => `${value} bikescore${value !== 1 ? 's' : ''}`}
                  precision={1}
                  readOnly
                  icon={<DirectionsBikeIcon fontSize="small" />}
                  emptyIcon={<DirectionsBikeIcon fontSize="small" />}
                />
              </ListItem>
            );
          case 'transitscore':
            ratingItem = scoreToRatingLabel(listItem.value, transitScoreRanges);
            return (
              <ListItem key={key} className="ratingList">
                <StyledSpan>
                  {listItem.label}
                  <br />
                  <span>{ratingItem.title}</span>
                  {' '}

                </StyledSpan>
                <StyledTransitRating
                  value={ratingItem.rank}
                  getLabelText={(value) => `${value} transitscore${value !== 1 ? 's' : ''}`}
                  precision={1}
                  readOnly
                  icon={<CommuteIcon fontSize="small" />}
                  emptyIcon={<CommuteIcon fontSize="small" />}
                />
              </ListItem>
            );
          default:
            return null;
        }
      }
      return null;
    });
  };
  const handleShowFinalStep = (callback) => {
    logAnalyticEvent('user_viewed_results');
    window.scrollTo(0, 0);
    callback();
    const linkedFields = {
      ...calculatorFields,
      ...resultsFields,
    };
    Object.entries(resultsFields).forEach(([key, field]) => {
      const functionFormula = {
        pmt,
        cashOnCashROI,
        capRate,
      };
      let equatedValue = field.value;
      if (field.formula) {
        const equation = substituteKeyWithCalculatedValueFromFormula(field.formula, linkedFields).join(' ');
        equatedValue = eval(equation);
        // eslint-disable-next-line no-restricted-globals
        if (key === 'cashOnCashROI' && (isNaN(equatedValue) || !isFinite(equatedValue))) {
          equatedValue = 0;
        }
      }
      if (field.functionFormula) {
        const values = field.formulas.map((formula) => {
          let equation = substituteKeyWithCalculatedValueFromFormula(formula, linkedFields).join(' ');
          if (equation === 'Yes') {
            equation = 1;
          }
          if (equation === 'No') {
            equation = 0;
          }
          return eval(equation);
        });

        equatedValue = functionFormula[field.functionFormula](...values);
      }
      linkedFields[key] = {
        ...linkedFields[key],
        value: equatedValue,
      };
      addCalculatedResults(key, equatedValue);
    });
  };
  const getContentPerStep = (step) => {
    const filteredContent = {};
    const optionsToBeFiltered = [];
    Object.entries(calculatorFields).forEach(([key, value]) => {
      if (value.stepper === step) {
        filteredContent[key] = { ...value, shouldDisplay: true };
        if (value.type === 'radio') {
          if (value.linkedFields.length) {
            if (value.value === 'No') {
              optionsToBeFiltered.push(...value.linkedFields.map((linkedField) => (
                { field: linkedField, shouldDisplay: false }
              )));
            } else {
              optionsToBeFiltered.push(...value.linkedFields.map((linkedField) => (
                { field: linkedField, shouldDisplay: true }
              )));
            }
          }
        }
      }
    });

    optionsToBeFiltered.forEach(({ field, shouldDisplay }) => {
      if (filteredContent[field]) {
        filteredContent[field].shouldDisplay = shouldDisplay;
      }
    });
    return filteredContent;
  };

  const convertROIToRange = (ROI) => {
    const ROIValue = +ROI;
    if (!ROIValue) {
      return 25;
    }
    if (ROIValue <= 0) { return 25; }
    if (ROIValue < 3) { return 25; }
    if (ROIValue < 7) { return 60; }
    if (ROIValue < 12) { return 75; }

    return 100;
  };
  const getLabelByROI = (ROI) => {
    if (ROI <= 0) {
      return {
        label: 'Terrible',
        description: 'This rating is based on the cash on cash return on investment. Would you willingly lose money on a deal?',
      };
    }
    if (ROI < 3) {
      return {
        label: 'Poor',
        description: 'This rating is based on the cash on cash return on investment. If inflation is 3% a year then you would be losing money on this investment year over year',
      };
    }
    if (ROI < 7) {
      return {
        label: 'Average',
        description: 'This rating is based on the cash on cash return on investment. This is a great place to start if you\'re a beginning investor or in a hot market this may be a great choice',
      };
    }
    if (ROI < 12) {
      return {
        label: 'Good',
        description: 'This rating is based on the cash on cash return on investment. This is a where most investor\'s target as an solid investment. This is a highly recommended choice',
      };
    }
    return {
      label: 'Amazing',
      description: 'This rating is based on the cash on cash return on investment. This is better than most skilled investor\'s target. One would call this a golden egg. This is very highly recommended choice',
    };
  };

  const [content, setContent] = useState(getContentPerStep(0));
  const [step, setStep] = useState(0);
  const [showRateAndShare, setShowRateAndShare] = useState(true);

  const handleStepChange = (newStep) => {
    setStep(newStep);
  };

  useEffect(() => {
    setContent(getContentPerStep(step));
  }, [step, calculatorFields]);

  const updatedResultsValues = (userCalculatorFields) => {
    const filteredContent = {};
    const optionsToBeFiltered = [];
    Object.entries(userCalculatorFields).map(([key, value]) => {
      filteredContent[key] = { ...value, shouldDisplay: true };
      if (value.type === 'radio') {
        if (value.linkedFields.length) {
          if (value.value === 'No') {
            optionsToBeFiltered.push(...value.linkedFields.map((linkedField) => (
              { field: linkedField, shouldDisplay: false })));
          } else {
            optionsToBeFiltered.push(...value.linkedFields.map((linkedField) => (
              { field: linkedField, shouldDisplay: true })));
          }
        }
      }

      return filteredContent;
    });
    optionsToBeFiltered.forEach(({ field, shouldDisplay }) => {
      if (filteredContent[field]) {
        filteredContent[field].shouldDisplay = shouldDisplay;
      }
    });

    return filteredContent;
  };

  const addDefaultSrc = ({ currentTarget }) => {
    // eslint-disable-next-line no-param-reassign
    currentTarget.src = PlaceHolderHouseImage;
  };

  const prettyPrintAddress = () => {
    const {
      city = '', housenumber = '', postcode = '', state = '', street = '',
    } = userLogin.propertyAddress || {};
    return typeof userLogin.propertyAddress === 'string' ? userLogin.propertyAddress
      : `${housenumber} ${street}, ${city} ${state} ${postcode}`;
  };

  const ROI = convertROIToRange(resultsFields.cashOnCashROI?.value);
  const { label, description } = getLabelByROI(resultsFields.cashOnCashROI?.value);
  const imageHeight = propertySummaryFields?.image?.value?.height
    ? propertySummaryFields?.image?.value?.height : 200;
  return (
    <WindowBackground className="App">
      <HorizontalLabelPositionBelowStepper
        content={
        (
          <>
            {(step === 0 || step === 4) ? (
              <PropertySummaryContainer>
                <h3>
                  {' '}
                  {prettyPrintAddress()}
                </h3>
                {
                  (
                    step === 4 && showRateAndShare) ? (
                      <Rating setShowRateAndShare={setShowRateAndShare} />
                    ) : null
                }
                <NeighborHoodContainer>
                  <img
                    style={{
                      height: Math.max(imageHeight, 200),
                      maxHeight: { xs: 400 },
                      borderRadius: '20px',
                    }}
                    onError={addDefaultSrc}
                    alt="The house from the offer."
                    src={
                        propertySummaryFields?.image?.value?.image?.length
                          ? propertySummaryFields?.image?.value?.image : PlaceHolderHouseImage
                      }
                  />
                  <StyledList isVertical>
                    {getRatings()}
                  </StyledList>

                </NeighborHoodContainer>
                <ListGroup list={propertySummaryFields} />

              </PropertySummaryContainer>
            ) : null}
            {step !== 4 ? (
              <InputFieldsContainer>
                {

                    Object.entries(content).map(([key, field]) => (
                      <InputField
                        key={key}
                        uniqueKey={key}
                        onChange={addFieldValue}
                        fields={calculatorFields}
                        {...field}
                        propertyDetails={propertyDetails}
                        showModal={showModal}
                        hideModal={hideModal}
                      />
                    ))
                  }
              </InputFieldsContainer>
            ) : (
              <div>
                <ProgressBar valuePercentage={ROI} label={label} description={description} />
                <StyledDivLeftRightColumn>
                  <CalculationsContainer>
                    {
                        Object.entries(resultsFields).map(([key, field]) => {
                          let equatedValue = field.isDollar ? new Intl.NumberFormat('en-US', {
                            currency: 'USD',
                            style: 'currency',
                          }).format(field.value) : field.value;
                          const fixedValue = equatedValue ? parseFloat(equatedValue).toFixed(2) : 0;
                          equatedValue = (equatedValue >= 0 && field.type === 'percent') ? `${fixedValue}%` : equatedValue;
                          return (
                            <DetailedDiv key={key}>
                              <StyledIcon icon={field.icon} title={field.tooltip}>
                                {field.icon ? icons[field.icon] : null}
                              </StyledIcon>
                              <ColumnFlex>
                                <span>{field.label}</span>
                                <span>
                                  {' '}
                                  {equatedValue}
                                </span>
                              </ColumnFlex>
                            </DetailedDiv>
                          );
                        })
                      }
                  </CalculationsContainer>
                  <ResultsSummaryContainer>
                    {
                        Object.entries(updatedResultsValues(calculatorFields)).map(
                          ([key, field]) => {
                            const value = field.shouldDisplay === false ? 0 : field.value;
                            const suffix = field.rangeLabel || (field.type === 'percent' ? '%' : '');
                            const prefix = field.showDollarSign ? '$' : '';
                            return (
                              <div key={key}>
                                <StyledTitle>{field.label}</StyledTitle>
                                <StyledText>{value ? `${prefix}${value}${suffix}` : '--'}</StyledText>
                              </div>
                            );
                          },
                        )
                      }
                  </ResultsSummaryContainer>
                </StyledDivLeftRightColumn>
              </div>
            )}
          </>
          )
        }
        handleStepChange={handleStepChange}
        handleShowFinalStep={handleShowFinalStep}
        nextButtonTitle={step === 3 ? 'Get Results' : 'Next'}
      />

    </WindowBackground>
  );
}

CalculatePage.propTypes = {
  addFieldValue: PropTypes.func.isRequired,
  logAnalyticEvent: PropTypes.func.isRequired,
  propertySummaryFields: PropTypes.shape({ image: PropTypes.string }).isRequired,
  calculatorFields: PropTypes.shape({}).isRequired,
  propertyDetails: PropTypes.shape({}).isRequired,
  resultsFields: PropTypes.shape({ cashOnCashROI: PropTypes.string }).isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  addCalculatedResults: PropTypes.func.isRequired,
  userLogin: PropTypes.shape({ propertyAddress: PropTypes.shape({}) }).isRequired,
};
