import styled from 'styled-components';

export const ButtonsContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    button {
        margin-left: 5px;
        margin-right: 5px;
    }
`;
