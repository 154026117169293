import React, { Component, createRef } from 'react';
import Button from '@mui/material/Button';
import { Typography } from '@material-ui/core';
import emailjs from '@emailjs/browser';
import { ButtonsContainer } from '../../components/Stepper/style';

import { StyledTextField, StyledPaper, StyledForm } from './style';

export default class Contact extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      message: '',
      email: '',
      subject: '',
      buttonText: 'Send Message',
      emailError: false,
      errorMessage: '',
      isSending: false,
    };

    this.form = createRef();
  }

  handleChangeEmail(e) {
    if (
      !e.target.value.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
    ) {
      this.setState({
        email: e.target.value,
      });
      this.setState({ emailError: true });

      const { email } = this.state;
      if (email === '') {
        this.setState({ emailError: false });
      }
    } else {
      this.setState({ email: e.target.value, emailError: false });
    }
  }

  resetForm = () => {
    this.setState({
      name: '',
      message: '',
      email: '',
      subject: '',
      buttonText: 'Send Message',
      errorMessage: '',
      isSending: false,
    });
  };

  formSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      buttonText: '...sending',
      isSending: true,
    });

    try {
      emailjs.sendForm(
        process.env.REACT_APP_SENDJS_SERVICE_ID,
        process.env.REACT_APP_SENDJS_TEMPLATE_ID,
        this.form.current,
        process.env.REACT_APP_SENDJS_USER_ID,
      )
        .then(() => {
          this.resetForm();
        }, (error) => {
          this.setState({ errorMessage: error });
        });
    } catch (error) {
      this.setState({ errorMessage: error });
    }
  };

  render() {
    const {
      message,
      name,
      email,
      emailError,
      subject,
      buttonText,
      errorMessage,
      isSending,
    } = this.state;
    return (
      <StyledPaper>
        <Typography> Get in Touch! </Typography>
        <p>
          Have a question? Would like to leave feedback?
          Want to meet up for coffee?
          Send a message!
        </p>
        <StyledForm onSubmit={(e) => this.formSubmit(e)} ref={this.form}>

          <StyledTextField
            placeholder="Enter your name"
            label="Name"
            variant="outlined"
            value={name}
            onChange={(e) => this.setState({ name: e.target.value })}
            required
            type="text"
            name="from_name"
          />

          <StyledTextField
            placeholder="Enter Subject"
            label="Subject"
            variant="outlined"
            value={subject}
            onChange={(e) => this.setState({ subject: e.target.value })}
            name="subject"
            required
          />
          <StyledTextField
            label="Message"
            placeholder="Enter Message"
            variant="outlined"
            multiline
            rowsMax={4}
            value={message}
            onChange={(e) => this.setState({ message: e.target.value })}
            required
            type="text"
            name="message"
          />

          <StyledTextField
            label="Email"
            placeholder="Enter email address"
            variant="outlined"
            value={email}
            onChange={(e) => this.handleChangeEmail(e)}
            error={emailError}
            required
            type="email"
            name="email"
          />

          <p>
            {' '}
            {errorMessage}
            {' '}
          </p>
          <ButtonsContainer>
            <Button
              style={{
                padding: '20px',
                borderRadius: '30px',
                width: '200px',
              }}
              type="submit"
              variant="contained"
              primary
              disabled={isSending}
            >
              {buttonText}
            </Button>
          </ButtonsContainer>
        </StyledForm>
      </StyledPaper>
    );
  }
}
