import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import Coffee from '@mui/icons-material/Coffee';
import Stepper from '@mui/material/Stepper';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { ButtonsContainer } from './style';
import BasicModal from '../BasicModal';

const steps = [
  'Property info',
  'Purchase info',
  'Rental info',
  'Expenses',
  'Results',
];

export default function HorizontalLabelPositionBelowStepper({
  content,
  nextButtonTitle = 'Next',
  previousButtonTitle = 'Back',
  handleStepChange,
  handleShowFinalStep,
}) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [showConfirmationDialog, setShowConfirmationDialog] = React.useState(false);

  const confirmFinalStep = () => {
    setShowConfirmationDialog(true);
    setActiveStep((prevActiveStep) => {
      handleStepChange(prevActiveStep + 1);
      return prevActiveStep + 1;
    });
  };

  const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin: 1rem;
    }
  `;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep + 1 === 4) {
        handleShowFinalStep(confirmFinalStep);
        return prevActiveStep;
      }
      if (prevActiveStep + 1 < steps.length) {
        handleStepChange(prevActiveStep + 1);
        return prevActiveStep + 1;
      }
      return prevActiveStep;
    });
  };

  const ModalContent = () => (
    <Content>
      <Coffee fontSize="large" />
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        Want to say thank you? Buy Vania a Coffee
      </Typography>
      <div>
        <form action="https://www.paypal.com/donate" method="post" target="_blank">
          <input type="hidden" name="hosted_button_id" value="DVC65CUUNL6SJ" />
          <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
          <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
        </form>
      </div>
    </Content>
  );

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      if ((prevActiveStep - 1) >= 0) {
        handleStepChange(prevActiveStep - 1);
        return prevActiveStep - 1;
      } return prevActiveStep;
    });
  };

  return (
    <>
      {
        showConfirmationDialog
          ? (
            <BasicModal
              shouldShouldModal={showConfirmationDialog}
              hideModal={() => setShowConfirmationDialog(false)}
              title="Buy Me A Coffee?"
              content={ModalContent()}
            />
          ) : null
      }
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <h1>
          {' '}
          {steps[activeStep]}
        </h1>
        <div>
          {content}
        </div>
        <ButtonsContainer>
          {activeStep === 0 ? null : <Button onClick={handleBack} variant="contained" color="primary">{previousButtonTitle}</Button>}
          {activeStep === 4 ? null : <Button onClick={handleNext} variant="contained" color="secondary">{nextButtonTitle}</Button>}
        </ButtonsContainer>
      </Box>
    </>
  );
}

HorizontalLabelPositionBelowStepper.defaultProps = {
  nextButtonTitle: 'Next',
  previousButtonTitle: 'Back',
  handleStepChange: Function.prototype,
  handleShowFinalStep: Function.prototype,
};

HorizontalLabelPositionBelowStepper.propTypes = {
  content: PropTypes.node.isRequired,
  nextButtonTitle: PropTypes.string,
  previousButtonTitle: PropTypes.string,
  handleStepChange: PropTypes.func,
  handleShowFinalStep: PropTypes.func,
};
