import React, { useState } from 'react';
import Rating from '@mui/material/Rating';
import Button from '@mui/material/Button';
import { OutlinedInput } from '@material-ui/core';
import PropTypes from 'prop-types';
import emailjs from '@emailjs/browser';
import { StyledRating, StyledFormControl } from './style';

export default function RatingAndFeedback({ setShowRateAndShare }) {
  const [value, setValue] = useState(0);
  const [userRated, setUserRated] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [isSending, setIsSending] = useState(false);
  return (
    <StyledRating>
      Enjoying this calculator or have some feedback?
      <Rating
        name="simple-controlled"
        value={value}
        onChange={(_event, newValue) => {
          setUserRated(true);
          setValue(newValue);
        }}
      />
      { userRated
        ? (
          <StyledFormControl>
            <OutlinedInput type="text" value={feedback} placeholder="Review" onChange={(event) => setFeedback(event.target.value)} />
            {
            isSending ? (
              <Button
                type="submit"
                onClick={() => {
                  setIsSending(true);
                  emailjs.send(
                    process.env.REACT_APP_SENDJS_SERVICE_ID,
                    process.env.REACT_APP_SENDJS_ACCESS_CODE_TEMPLATE_ID,
                    { access_code: `Rating: ${value} \n Message ${feedback}` },
                    process.env.REACT_APP_SENDJS_USER_ID,
                  ).then(() => {
                    setIsSending(false);
                    setFeedback('');
                    setShowRateAndShare(false);
                  });
                }}
              >
                {' '}
                Send
                {' '}

              </Button>
            ) : null
      }
          </StyledFormControl>
        )
        : null }

    </StyledRating>
  );
}

RatingAndFeedback.propTypes = {
  setShowRateAndShare: PropTypes.func.isRequired,
};
