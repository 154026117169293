import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { PageContainer } from './style';

class NotFoundPage extends PureComponent {
  render() {
    return (
      <PageContainer>
        <h1>404 Page Not Found </h1>
        <p>
          <Link to="/">Go to Home </Link>
        </p>
      </PageContainer>
    );
  }
}
export default NotFoundPage;
