import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';
import { ButtonSizes } from '../../theme/sizes';

import { rotate360 } from '../../utils';
import { DEVICE_SIZE } from '../../constants/deviceSizes';

export const StyledBox = styled(Box)`
    justify-content:center;
    align-content: center;
    min-width: 100%;
    padding-top: 35px;

    .color-paper {
        margin-right: 50px;
    }

    .color-paper > .color-card {
        background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
        font-size: 28px;
        font-weight: 400;
        color: white;
    }
`;

export const PaymentsContainer = styled.div`
    min-height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
`;

export const StyledCardActions = styled(CardActions)`
    display: flex;
    justify-content: center;
    padding-bottom: 20px !important;
`;

export const StyledButton = styled(Button)`
    background: #333996 !important;
    color: white !important;
    padding: 10px !important;
    height: ${ButtonSizes.heightSmall} !important;
    border-radius: calc(${ButtonSizes.heightSmall}/2) !important;
    min-width: ${ButtonSizes.widthSmall} !important;
`;
export const StyledPaper = styled(Paper)`
    display: inline-flex;
    @media (max-width: ${DEVICE_SIZE.tablet}) {
     width: 100%;
     margin-bottom: 30px;
  }
`;

export const StyledCard = styled(Card)`
min-width: 100%;
`;

export const StyledPrice = styled.span`
    color: #333999;
    font-size: 100px;
    text-shadow: -4px 3px transparent,
        -90px 0 rgb(255 255 255 / 20%),
        90px 0 rgb(255 255 255 / 20%) !important;
`;

export const StyledContent = styled.span`
    font-size: 18px;
`;

export const StyledImage = styled.img`
    max-width: 200px;
`;

export const DiscountContainer = styled.div`
    width: 50px;
    height: 50px;
    border-radius:35px;
    position: relative;
    right: 35px;
    top: -35px;
    min-width: 70px;
    min-height: 70px;
    background: #333999;
    display: inline-flex;
    justify-items: center;
    align-content: center;
    justify-content: center;
    align-items: center;
    color: white;
`;

export const Spinner = styled.div`
    position: absolute;
    display: inline-block;
    top: 50%;
    right: 50%;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(186, 224, 252, .3);
    border-radius: 50%;
    border-top-color: #333999;
    animation:  ${rotate360}  1s ease-in-out infinite;
    -webkit-animation:  ${rotate360}  1s ease-in-out infinite;
    margin-left: 50%;
`;

export const PaymentConfirmationContainer = styled.div`
    border: 1px solid #191c4b;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 20px;
`;

export const StyledLinkButton = styled(Button)`
    padding: 0;
    margin: 0;
    font-size: 0.8em;
    vertical-align: baseline;
`;
