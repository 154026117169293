import styled from 'styled-components';
import { FormControl } from '@material-ui/core';

export const StyledRating = styled.div`
  display: flex ;
  justify-content: center ;
  width: 100%;
  background: transparent;
  align-items: center;
  padding: 20px;
  border: 1px solid #191c4b;
  margin-bottom: 10px;
`;

export const StyledFormControl = styled(FormControl)`
  display: inline;
`;
