import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const PrimaryColor = '#004113';
const SecondaryColor = '#005A4E';

const PrimaryBackgroundColor = '#b6dad8';
const SecondaryBackgroundColor = '#f7fffe';

export const StyledLinked = styled(Link)`
    color:  ${(props) => (props.navigationTheme === 'primary' ? PrimaryColor : SecondaryColor)};
`;

export const StyledToolbar = styled(Toolbar)`
    background: ${(props) => (props.$navigationTheme === 'primary' ? PrimaryBackgroundColor : SecondaryBackgroundColor)};
    align-self: center;
`;

export const StyledAppBar = styled(AppBar)`
    background: ${(props) => (props.$navigationTheme === 'primary' ? PrimaryBackgroundColor : SecondaryBackgroundColor)};
    box-shadow: none;
`;

export const StyledTypography = styled(Typography)`
    color: ${(props) => (props.$navigationTheme === 'primary' ? PrimaryColor : SecondaryColor)};
`;
