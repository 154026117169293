export const CalculatorFields = {
  numberOfUnits: {
    label: 'Number of Units',
    type: 'number',
    apiKey: 'num_of_units',
    editable: true,
    stepper: 0,
    value: 0,
    min: 0,
  },
  numberOfRooms: {
    label: 'Number of Beds',
    type: 'number',
    apiKey: 'beds',
    editable: true,
    stepper: 0,
    value: 0,
    min: 0,
  },
  numberOfBathrooms: {
    label: 'Number of Baths',
    type: 'number',
    apiKey: 'baths',
    editable: true,
    stepper: 0,
    value: 0,
    min: 0,
  },
  purchasePrice: {
    label: 'Purchase Price',
    type: 'number',
    apiKey: 'listPrice',
    editable: true,
    step: '10000',
    showDollarSign: true,
    stepper: 1,
    formulaLink: 'insurance',
    value: 0,
    min: 0,

  },
  useLoan: {
    label: 'Use Loan?',
    type: 'radio',
    options: ['Yes', 'No'],
    editable: true,
    linkedFields: ['downPayment', 'interestRate', 'ltvRate', 'loanTerm', 'pointsCharged', 'pmiRate'],
    value: 'Yes',
    stepper: 1,
  },
  downPayment: {
    label: 'Down Payment',
    type: 'percent',
    editable: true,
    displayPercentToDollar: true,
    min: 0,
    max: 100,
    linkedField: 'purchasePrice',
    value: 20,
    step: 0.1,
    stepper: 1,
    formulaLink: 'ltvRate',
    helpText: 'When purchasing a rental property, most investors obtain a loan from a bank, credit union, or another lender. Although down payment requirements change based on numerous factors, most loans require between 20 and 30% of the purchase price for a down payment. Adjust the down payment in this section to match the type of loan you\'ll obtain.',
  },
  interestRate: {
    label: 'Interest Rate',
    type: 'percent',
    editable: true,
    min: '0',
    max: '100',
    step: 0.1,
    marks: [0, 100],
    stepper: 1,
    value: 3.75,
  },
  loanTerm: {
    label: 'Loan Term',
    type: 'range',
    editable: true,
    min: '0',
    max: '30',
    value: 30,
    rangeLabel: ' years',
    marks: [0, 15, 30],
    stepper: 1,
    helpText: 'A loan term is the length of time that which a loan is scheduled to be paid back. The longer the time frame, the lower the monthly loan payment. In residential real estate, such as single-family or small multifamily properties, 30-year mortgages are most common—though many do opt for a 15-year mortgage. In commercial real estate, 20 or 25-year mortgages are more common.',
  },
  pointsCharged: {
    label: 'Points Charged',
    type: 'number',
    editable: true,
    stepper: 1,
    helpText: 'Sometime when you take out a loan, you will pay "points" (fees) on the loan. One point is equal to 1% of the loan amount. For example, a $100,000 loan with 2 points would be $2,000.',
    value: 0,
    min: 0,
  },
  ltvRate: {
    label: 'LTV (Loan-To-Value) Rate',
    type: 'percent',
    editable: false,
    stepper: 1,
    formula: '100 - downPayment',
    min: 0,
    value: 0,
    step: 0.1,
  },
  pmiRate: {
    label: 'PMI Rate',
    type: 'percent',
    editable: true,
    min: '0',
    max: '100',
    value: 3.5,
    stepper: 1,
    helpText: 'If your down payment is less than 20% you may have a pmi payment added to your mortgage. You may have the option to pay for your pmi upfront. If this is the case set pmi to 0. If you don\'t have this option pmi rate is typically 0.22 - 3.5. Ask your lender for your pmi rate, if you are unsure enter a value between 0.58% to 1.86%',
  },
  closingCost: {
    label: 'Closing Cost',
    type: 'number',
    editable: true,
    showDollarSign: true,
    stepper: 1,
    value: 0,
    min: 0,
  },
  rehabBudget: {
    label: 'Rehab Budget',
    type: 'number',
    editable: true,
    showDollarSign: true,
    stepper: 1,
    value: 0,
    min: 0,
  },
  miscellaneous: {
    label: 'Other Miscellaneous',
    type: 'number',
    editable: true,
    showDollarSign: true,
    stepper: 1,
    helpText: 'These are other costs you may expect to carry such as holding costs.',
    value: 0,
    min: 0,
  },
  rentalIncome: {
    label: 'Rental Income',
    type: 'number',
    editable: true,
    apiKey: '',
    showDollarSign: true,
    stepper: 2,
    helpText: 'This is the total amount of rent you could receive on the property if it is 100% occupied.',
    value: 0,
    min: 0,
  },
  additionalIncome: {
    label: 'Additional Income',
    type: 'number',
    editable: true,
    showDollarSign: true,
    stepper: 2,
    helpText: 'This is most common if you are analyzing a property and want to add other income sources, such as parking, back-billing for water usage or coin-operated laundry.',
    value: 0,
    min: 0,
  },
  taxes: {
    label: 'Taxes (Annual)',
    type: 'number',
    editable: true,
    apiKey: 'tax',
    showDollarSign: true,
    stepper: 3,
    helpText: 'If the property is listed by a real estate agent, you can ask your agent about the current tax bill. Often this information is displayed on the online listing as well, such as on Zillow.com or Realtor.com. Ddetails on current and historical tax records can be also be found on your County Assessor\'s Website.',
    value: 0,
    min: 0,
  },
  insurance: {
    label: 'Insurance (Annual)',
    type: 'number',
    editable: true,
    showDollarSign: true,
    stepper: 3,
    helpText: 'Insurance costs differ greatly depending on location, property type, and property condition. To determine your property\'s insurance costs, talk with a local or online insurance agent to get a quote. Keep in mind, some areas require extra insurance, such as flood, hurricane, or earthquake insurance.',
    value: 0,
    min: 0,
  },
  repairs: {
    label: 'Repairs & Maintenance',
    type: 'percent',
    editable: true,
    stepper: 3,
    value: 5,
    resultsFormula: '( rentalIncome + additionalIncome ) * ( repairs / 100 )',
    helpText: 'All properties require ongoing maintenance when things break. This number is usually expressed as a percentage of the rent. Typical repair costs tend to be between 5-15% of the gross monthly rent.',
    min: 0,
  },
  vacancy: {
    label: 'Vacancy',
    type: 'percent',
    editable: true,
    stepper: 3,
    value: 3,
    resultsFormula: '( rentalIncome + additionalIncome ) * ( vacancy / 100 )',
    helpText: 'Vacancy rate is the cost of the property sitting empty due to tenant turnover. This number can vary depending on the area, so consult with a local property manager or landlord as to the local norms. Typical vacancy rates are between 3%-10% of the gross monthly rent, but again, that can depend on the area.',
    min: 0,
  },
  capex: {
    label: 'Cap Ex',
    type: 'percent',
    editable: true,
    stepper: 3,
    value: 5,
    resultsFormula: '( rentalIncome + additionalIncome ) * ( capex / 100 )',
    helpText: 'CapEx, short for capital expenditures, are the large less-than-frequent improvements done to a property such as roofs, parking lots, siding, or appliances. CapEx will depend on numerous factors such as property type, the location of the property, and the age of the property. Typically, many investors allocate between 5-15% of the rent for CapEx.',
    min: 0,
  },
  managementFees: {
    label: 'Management Fees',
    type: 'percent',
    editable: true,
    stepper: 3,
    value: 10,
    resultsFormula: '( rentalIncome + additionalIncome ) * ( managementFees / 100 )',
    helpText: 'This number is usually expressed as a percentage. Typical rates hover between 7-12% of the gross monthly rent.',
    min: 0,
  },
  electricity: {
    label: 'Electricity (Monthly)',
    type: 'number',
    editable: true,
    showDollarSign: true,
    stepper: 3,
    value: 0,
    min: 0,
  },
  gas: {
    label: 'Gas  (Monthly)',
    type: 'number',
    editable: true,
    showDollarSign: true,
    stepper: 3,
    value: 0,
    min: 0,
  },
  water: {
    label: 'Water (Monthly)',
    type: 'number',
    editable: true,
    showDollarSign: true,
    stepper: 3,
    value: 0,
    min: 0,
  },
  sewer: {
    label: 'Sewer  (Monthly)',
    type: 'number',
    editable: true,
    showDollarSign: true,
    stepper: 3,
    value: 0,
    min: 0,
  },
  garbage: {
    label: 'Garbage  (Monthly)',
    type: 'number',
    editable: true,
    showDollarSign: true,
    stepper: 3,
    value: 0,
    min: 0,
  },
  hoaFees: {
    label: 'HOA Fees (Monthly)',
    type: 'number',
    editable: true,
    showDollarSign: true,
    stepper: 3,
    apiKey: 'hoa_dues',
    helpText: 'HOA fees can range from a few dollars a month to thousands of dollars per month, based on the community or location. Be sure to check and see if your property is located within an HOA and, if so, ask your real estate agent what the monthly HOA fees are',
    value: 0,
    min: 0,
  },
  miscellaneousCosts: {
    label: 'Other Miscellaneous Costs  (Monthly)',
    type: 'number',
    editable: true,
    showDollarSign: true,
    stepper: 3,
    value: 0,
    min: 0,
  },
};

export const PropertySummaryFields = {
  image: {
    type: 'image',
    editable: false,
    value: '',
  },
  title: {
    type: 'text',
    editable: false,
    value: '',
  },
  homeType: {
    label: 'Property Type',
    editable: false,
    value: 'Unknown',
  },
  yearBuilt: {
    label: 'Year Built',
    editable: false,
    value: 'Unknown',
  },
  sqft: {
    label: 'Square Footage',
    editable: false,
    value: 'Unknown',
  },
  daysOnMarket: {
    label: 'Day on Market',
    editable: false,
    value: 'Unknown',
  },
  parkingSpots: {
    label: 'Parking Spots',
    editable: false,
    value: 'Unknown',
  },
  walkscore: {
    label: 'Walk Score',
    type: 'score',
    editable: false,
    value: 'Unknown',
  },
  transitscore: {
    label: 'Transit Score',
    type: 'score',
    editable: false,
    value: 'Unknown',
  },
  bikescore: {
    label: 'Bike Score',
    type: 'score',
    editable: false,
    value: 'Unknown',
  },
};

export const ResultsCalculatedFields = {
  mortgage: {
    label: 'Mortgage',
    type: 'number',
    editable: false,
    showDollarSign: true,
    functionFormula: 'pmt',
    formulas: ['useLoan', '( interestRate / 100 ) / 12', 'loanTerm * 12', 'purchasePrice - downPayment', 'downPayment', 'purchasePrice', 'pmiRate'],
    isDollar: true,
    icon: 'House',
  },
  totalInvested: {
    label: 'Total Invested',
    type: 'number',
    editable: false,
    formula: '( downPayment + closingCost + rehabBudget + miscellaneous )',
    isDollar: true,
    icon: 'Savings',
    tooltip: ' Down Payment \n Closing Cost \n Rehab Budget \n miscellaneous \n',
  },
  monthlyIncome: {
    label: 'Monthly Income',
    type: 'number',
    editable: false,
    formula: 'rentalIncome + additionalIncome',
    isDollar: true,
    icon: 'NetIncome',
    tooltip: ' Rental Income \n Additional Income \n ',
  },
  monthlyExpenses: {
    label: 'Monthly Expenses',
    type: 'number',
    editable: false,
    formula: '( ( taxes / 12 ) + ( insurance / 12 ) + ( mortgage + ( repairs / 100 ) * rentalIncome ) + ( ( vacancy / 100 ) * rentalIncome ) + ( ( capex / 100 ) * rentalIncome ) + ( ( managementFees / 100 ) * rentalIncome ) + electricity + water + sewer + hoaFees + garbage + miscellaneousCosts )',
    isDollar: true,
    icon: 'Expense',
    tooltip: ' Taxes \n Insurance \n Mortgage \n Repairs \n Vacancy \n Capex \n Management \n Utilities \n Miscellaneous \n',
  },
  monthlyCashFlow: {
    label: 'Monthly Cash Flow',
    type: 'number',
    editable: false,
    formula: '( rentalIncome + additionalIncome ) - ( mortgage + ( taxes / 12 ) + ( insurance / 12 ) + ( ( repairs / 100 ) * rentalIncome ) + ( ( vacancy / 100 ) * rentalIncome ) + ( ( capex / 100 ) * rentalIncome ) + ( ( managementFees / 100 ) * rentalIncome ) + electricity + water + sewer + hoaFees + garbage + miscellaneousCosts )',
    isDollar: true,
    icon: 'Repeat',
    tooltip: ' Income - Expenses \n',
  },
  annualCashFlow: {
    label: 'Annual Cash Flow',
    type: 'number',
    editable: false,
    formula: '( ( rentalIncome + additionalIncome ) - ( mortgage + ( taxes / 12 ) + ( insurance / 12 ) + ( ( repairs / 100 ) * rentalIncome ) + ( ( vacancy / 100 ) * rentalIncome ) + ( ( capex / 100 ) * rentalIncome ) + ( ( managementFees / 100 ) * rentalIncome ) + electricity + water + sewer + hoaFees + garbage + miscellaneousCosts ) ) * 12',
    isDollar: true,
    icon: 'RepeatYearly',
  },
  cashOnCashROI: {
    label: 'Cash on Cash ROI',
    type: 'percent',
    functionFormula: 'cashOnCashROI',
    formulas: ['( downPayment + closingCost + rehabBudget + miscellaneous )', '( ( ( ( rentalIncome + additionalIncome ) - ( mortgage + ( taxes / 12 ) + ( insurance / 12 ) + ( ( repairs / 100 ) * rentalIncome ) + ( ( vacancy / 100 ) * rentalIncome ) + ( ( capex / 100 ) * rentalIncome ) + ( ( managementFees / 100 ) * rentalIncome ) + electricity + water + sewer + hoaFees + garbage + miscellaneousCosts ) ) * 12 ) / ( ( downPayment + closingCost + rehabBudget + miscellaneous ) ) ) * 100', '( rentalIncome + additionalIncome ) - ( mortgage + ( taxes / 12 ) + ( insurance / 12 ) + ( ( repairs / 100 ) * rentalIncome ) + ( ( vacancy / 100 ) * rentalIncome ) + ( ( capex / 100 ) * rentalIncome ) + ( ( managementFees / 100 ) * rentalIncome ) + electricity + water + sewer + hoaFees + garbage + miscellaneousCosts )'],
    editable: false,
    icon: 'CashOnCashReturn',
  },
  capRate: {
    label: 'Cap Rate',
    type: 'percent',
    functionFormula: 'capRate',
    formulas: ['( ( rentalIncome + additionalIncome ) - ( mortgage + ( taxes / 12 ) + ( insurance / 12 ) + ( ( repairs / 100 ) * rentalIncome ) + ( ( vacancy / 100 ) * rentalIncome ) + ( ( capex / 100 ) * rentalIncome ) + ( ( managementFees / 100 ) * rentalIncome ) + electricity + water + sewer + hoaFees + garbage + miscellaneousCosts ) ) * 12', 'purchasePrice'],
    editable: false,
    icon: 'Insights',
  },
};

export default CalculatorFields;
