import { logAnalyticEvent } from './firebase';

const apiKey = process.env.REACT_APP_GEOAPIFY_API_KEY;

export const searchText = (address) => {
  const requestOptions = {
    method: 'GET',
  };
  logAnalyticEvent('address_lookup', {
    address,
  });
  return fetch(`https://api.geoapify.com/v1/geocode/search?text=${address}&apiKey=${apiKey}`, requestOptions);
};
