/* eslint-disable no-eval */
import {
  FormControl, InputAdornment, OutlinedInput, Slider,
} from '@material-ui/core';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getLinkedFieldValue, substituteKeyWithValueFromFormula } from '../../utils';
import { InputFieldFieldContainer, StyledHelpIcon, StyledTitle } from './style';

class InputField extends Component {
  componentDidMount() {
    const {
      uniqueKey,
      apiKey,
      formula,
      onChange,
      value,
      propertyDetails = {},
      fields,
    } = this.props;
    onChange(uniqueKey, value);

    if (apiKey?.length && propertyDetails[apiKey]) {
      onChange(uniqueKey, propertyDetails[apiKey]);
    }
    if (formula?.length) {
      const equation = substituteKeyWithValueFromFormula(formula, fields).join(' ');
      const calculatedValue = eval(equation);
      onChange(uniqueKey, calculatedValue);
    }
  }

  generateInputField = (value) => {
    const {
      step,
      showDollarSign,
      uniqueKey,
      editable,
      min,
      max,
      rangeLabel = '',
      marks: markValues,
      options = [],
      isDollar,
    } = this.props;
    let { type } = this.props;
    const startAdornment = showDollarSign ? <InputAdornment position="start">$</InputAdornment> : null;
    const endAdornment = type === 'percent' ? <InputAdornment position="end">%</InputAdornment> : null;

    switch (type) {
      case 'range': {
        const marks = markValues.map((mark) => ({
          value: mark,
          label: `${mark}${rangeLabel}`,
        }));
        return (
          <div>
            <Slider
              key={uniqueKey}
              type={type}
              value={value}
              onChange={this.updateValue}
              valueLabelDisplay="auto"
              step={step}
              marks={marks}
              min={+min}
              max={+max}
            />
          </div>
        );
      }

      case 'radio':
        return (
          <RadioGroup key={uniqueKey} row defaultValue={value} onChange={this.updateValue}>
            {options.map((option) => (
              <FormControlLabel key={option} value={option} control={<Radio />} label={option} />
            ))}
          </RadioGroup>
        );
      default: {
        if (type === 'percent') {
          type = 'number';
        }
        const updatedValue = isDollar ? Number(value).toFixed(2) : value;
        // figure this out - display not modify value -_-
        return (
          <OutlinedInput
            key={uniqueKey}
            startAdornment={startAdornment}
            endAdornment={endAdornment}
            variant="standard"
            type={type}
            value={updatedValue}
            disabled={!editable}
            onChange={this.updateValue}
            inputProps={{
              step,
              min,
            }}
          />
        );
      }
    }
  };

  onFocusChange = (ev, value) => {
    const inputValue = value || ev.target.value;
    const {
      uniqueKey, formulaLink, onChange, fields,
    } = this.props;
    onChange(uniqueKey, inputValue, false);

    if (formulaLink) {
      const { formula } = fields[formulaLink];
      const equation = substituteKeyWithValueFromFormula(formula, fields).join(' ');
      const calculatedValue = Number(eval(equation));
      onChange(formulaLink, calculatedValue);
    }
  };

  updateValue = async (ev, value) => {
    let inputValue = value || ev.target.value;
    const {
      uniqueKey, formulaLink, min, fields, onChange,
    } = this.props;
    if (inputValue !== '' && +inputValue < +min) {
      inputValue = 0;
    }

    onChange(uniqueKey, inputValue, true);
    fields[uniqueKey].value = inputValue;
    if (formulaLink) {
      const { formula } = fields[formulaLink];
      const equation = substituteKeyWithValueFromFormula(formula, fields).join(' ');
      const calculatedValue = Number(eval(equation));
      onChange(formulaLink, calculatedValue);
    }
  };

  render() {
    const {
      label,
      displayPercentToDollar,
      uniqueKey,
      shouldDisplay,
      helpText,
      showModal,
      fields,
      linkedField,
    } = this.props;
    const linkedFieldValue = getLinkedFieldValue(fields, uniqueKey, linkedField);
    const value = fields[uniqueKey]?.value;
    const textLabel = fields[uniqueKey]?.textLabel || null;
    const modalContent = helpText ? (
      <div>
        {' '}
        {helpText}
        {' '}
      </div>
    ) : null;
    return (
      <InputFieldFieldContainer shouldDisplay={shouldDisplay}>
        <StyledTitle>
          <span>
            {' '}
            {label}
            {' '}
            {textLabel}
            {' '}
          </span>
          {helpText ? (
            <StyledHelpIcon onClick={() => showModal(modalContent, `${label} ${textLabel || ''} `)} />
          ) : null}
        </StyledTitle>
        <FormControl fullWidth sx={{ m: 1 }}>
          {this.generateInputField(value)}
        </FormControl>
        <p>
          {displayPercentToDollar ? `$${linkedFieldValue.toLocaleString('en-US')}` : null}
        </p>
      </InputFieldFieldContainer>
    );
  }
}

InputField.defaultProps = {
  type: 'text',
  displayPercentToDollar: false,
  onChange: () => { },
  fields: {},
  options: [],
  formulaLink: undefined,
  shouldDisplay: true,
  helpText: undefined,
  apiKey: undefined,
  formula: '',
  min: 0,
  max: undefined,
  isDollar: false,
  step: 1,
  editable: false,
  showDollarSign: false,
  rangeLabel: '',
  marks: [],
  linkedField: '',
  value: '',
};

InputField.propTypes = {
  helpText: PropTypes.string,
  shouldDisplay: PropTypes.bool,
  formulaLink: PropTypes.string,
  uniqueKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  displayPercentToDollar: PropTypes.bool,
  onChange: PropTypes.func,
  fields: {
    label: PropTypes.string,
    type: PropTypes.string,
    editable: PropTypes.bool,
    displayPercentToDollar: PropTypes.bool,
    linkedField: PropTypes.string,
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.number,
    stepper: PropTypes.number,
    step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  },
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  apiKey: PropTypes.string,
  formula: PropTypes.string,
  propertyDetails: PropTypes.shape({}).isRequired,
  isDollar: PropTypes.bool,
  step: PropTypes.number,
  showDollarSign: PropTypes.bool,
  editable: PropTypes.bool,
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rangeLabel: PropTypes.string,
  marks: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired),
  showModal: PropTypes.func.isRequired,
  linkedField: PropTypes.string,
};

export default InputField;
