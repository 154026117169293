import { List } from '@material-ui/core';
import Rating from '@mui/material/Rating';
import styled from 'styled-components';
import { DEVICE_SIZE } from '../../constants/deviceSizes';

export const InputFieldsContainer = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    border: 1px solid #191c4b;
    border-radius: 25px;
    background: transparent;

    @media (min-width: ${DEVICE_SIZE.tablet}) {
      margin-left: 15%;
      margin-right: 15%;
  }
`;

export const ButtonContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
button {
    margin: 5px;
}
`;
export const PropertySummaryContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;

export const NeighborHoodContainer = styled.div`
    justify-content: center;
    flex-wrap: wrap;
    display: flex;
`;

export const StyledWalkRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75',
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47',
  },
});

export const StyledBikeRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75',
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47',
  },
});

export const StyledTransitRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75',
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47',
  },
});

export const StyledList = styled(List)`
    display: flex;
    flex-direction: ${(props) => (props.isVertical ? 'column' : 'row')};
    align-self: center;

    .ratingList {
        justify-content: space-between;
        span {
            padding-right: 10px;
        }
    }

    li {
      width: auto !important;
    }
  `;

export const StyledSpan = styled.div`
    font-weight: bold;
    span {
        font-weight: normal;
    }
  `;

export const StyledTitle = styled.p`
font-weight: bold;
text-align: center;
`;

export const StyledText = styled.p`
font-weight: normal;
text-align: center;
`;

export const ResultsSummaryContainer = styled.div`
    padding: 20px;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    background: transparent;
    justify-content: space-between;
    align-items: flex-start;
    border: 1px solid #191c4b;
    border-radius: 25px;

    div {
        flex: 1 0 20%;
    }
`;

export const StyledIcon = styled.div`
  min-height: 80px;
  min-width: 80px;
  border-radius: 40px;
  border: 1px solid #005A4E;
  display: ${(props) => (props.icon ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  margin-right: 10px;

  svg {
    font-size: 4em;
  }

  img {
    max-height: 60px;
  }
`;

export const DetailedDiv = styled.div`
display: flex;
flex-direction: row;
align-items: center;
flex: 1 0 20%;
margin: 11px;
`;

export const ColumnFlex = styled.div`
display: flex;
flex-direction: column;
width: 100%;
align-items: flex-start;
`;
export const CalculationsContainer = styled.div`
display: flex;
flex-wrap: wrap;
margin: 10px;
`;

export const StyledDivLeftRightColumn = styled.div`
display: flex;
flex-wrap: wrap;
flex-direction: row;
border: 1px solid black;
border-radius: 25px;
`;
