import { ListItem, ListItemText } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { StyledList } from './style';

class ListGroup extends PureComponent {
  render() {
    const { isVertical, list } = this.props;
    return (
      <StyledList dense isVertical={isVertical}>
        {Object.entries(list).map(([key, listItem]) => {
          const value = listItem.isDollar ? new Intl.NumberFormat('en-US', {
            currency: 'USD',
            style: 'currency',
          }).format(listItem.value) : listItem.value || 'No Data';
          if (listItem.type === 'image' || listItem.type === 'text' || listItem.type === 'score') {
            return null;
          }
          return (
            <ListItem key={key}>
              <ListItemText primary={listItem.label} secondary={value} />
            </ListItem>
          );
        })}
      </StyledList>
    );
  }
}

ListGroup.defaultProps = {
  list: {},
  isVertical: false,
};

ListGroup.propTypes = {
  isVertical: PropTypes.bool,
  list: PropTypes.shape(),
};

export default ListGroup;
