export const fetchPropertyDetails = (propertyObject) => {
  const {
    housenumber, street, city, state, postcode,
  } = propertyObject;
  const address = `${housenumber} ${street}`;
  const params = {
    state,
    address,
    city,
    zip_code: postcode,
  };

  const url = new URL('https://mashvisor-api.p.rapidapi.com/property');
  url.search = new URLSearchParams(params).toString();

  return fetch(url, {
    method: 'GET',
    headers: {
      'x-rapidapi-host': 'mashvisor-api.p.rapidapi.com',
      'x-rapidapi-key': process.env.REACT_APP_RAPID_API_KEY,
    },
  });
};
