// Import the functions you need from the SDKs you need
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth, signInAnonymously } from 'firebase/auth';
import {
  doc, getDoc, getFirestore, setDoc,
} from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'findmynextinvestment-23e3f.firebaseapp.com',
  projectId: 'findmynextinvestment-23e3f',
  storageBucket: 'findmynextinvestment-23e3f.appspot.com',
  messagingSenderId: '1011394396575',
  appId: '1:1011394396575:web:48452bcca786ac4d497daa',
  measurementId: 'G-DDECML4BS8',
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore();

export const logAnalyticEvent = (name, item = {}) => {
  logEvent(analytics, name, item);
};

export const validateAccessCodeAndGetCount = async (accessCode) => {
  const docRef = doc(db, 'accessCodes', accessCode);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const { count } = docSnap.data();
    if (count >= 1) {
      await setDoc(docRef, {
        ...docSnap.data(),
        count: count - 1,
      });
      logAnalyticEvent('user_used_valid_access_codes');
      return count;
    }
    logAnalyticEvent('user_used_all_codes');
    return 0;
  }
  logAnalyticEvent('invalid_access_code', {
    attemptedCode: accessCode,
  });
  return 0;
};

export const logInUser = () => {
  const auth = getAuth();
  logAnalyticEvent('login', {
    method: 'Anonymous',
  });
  return signInAnonymously(auth);
};

export const setAccessCode = (id, count, email, firstName, lastName, currency, value) => {
  const docRef = doc(db, 'accessCodes', id);
  logAnalyticEvent('purchase', {
    currency,
    value,
    itemId: id,
  });
  setDoc(docRef, {
    firstName,
    lastName,
    count,
    email,
  });
};
