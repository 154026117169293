/* eslint-disable no-restricted-properties */
/* eslint-disable no-restricted-globals */
import { keyframes } from 'styled-components';

export const getLinkedFieldValue = (
  fields,
  uniqueKey,
  linkedField,
) => {
  const { value = 0 } = fields[uniqueKey];

  let linkedFieldToValue = linkedField ? fields[linkedField].value : 1;
  linkedFieldToValue = +(linkedFieldToValue || 0);

  if (isNaN(linkedFieldToValue) || isNaN(value)) {
    return 0;
  }

  return ((value / 100) * linkedFieldToValue);
};

export const fieldValue = (fields, key) => {
  const value = fields[key]?.value;

  return isNaN(value) ? 0 : value;
};

export const substituteKeyWithCalculatedValueFromFormula = (formula, fields) => {
  const formulaArray = formula ? formula.split(' ') : [];
  const results = [];
  formulaArray.forEach((statement) => {
    if (statement.match(/[-+/*]/) || statement === '(' || statement === ')') {
      results.push(statement);
    } else if (isNaN(statement)) {
      if (fields[statement].linkedField) {
        const calculatedValue = getLinkedFieldValue(
          fields,
          statement,
          fields[statement].linkedField,
        );
        results.push(calculatedValue);
      } else {
        results.push((fields[statement].value) || 0);
      }
    } else {
      results.push(+statement);
    }
  });

  return results;
};

export const substituteKeyWithValueFromFormula = (formula, fields) => {
  const formulaArray = formula ? formula.split(' ') : [];
  const results = [];
  formulaArray.forEach((statement) => {
    if (statement.match(/[-+/*]/) || statement === '(' || statement === ')') {
      results.push(statement);
    } else if (isNaN(statement)) {
      if (fields[statement].linkedField) {
        results.push(fields[statement].value);
      } else {
        results.push((fields[statement].value) || 0);
      }
    } else {
      results.push(+statement);
    }
  });

  return results;
};

export const pmi = (useLoan, downPayment, purchasePrice, pmiRate) => {
  if (downPayment / purchasePrice >= 0.2 || !useLoan) {
    return 0;
  }
  return ((purchasePrice - downPayment) * (pmiRate / 100));
};

export const cashOnCashROI = (totalInvested, cashOnCash, monthlyCashFlow) => {
  if (totalInvested > 0) {
    return cashOnCash.toFixed(2);
  }
  if (totalInvested === 0) {
    return ((monthlyCashFlow * 12) / 100).toFixed(2);
  }
  return 0.00;
};

export const capRate = (noi, purchasePrice) => {
  if (purchasePrice === 0) { return 0; }
  return ((noi / purchasePrice) * 100).toFixed(3);
};

// var interest    = 0.07,     // Annual interest
//     years       = 5,        // Lifetime of loan (in years)
//     present     = 10000,    // Present value of loan
//     future      = 20000,    // Future value of loan
//     beginning   = 1;        // Calculated at start of each period
export const pmt = (
  useLoan,
  interestRate,
  numberOfPeriods,
  presentValue,
  downPayment,
  purchasePrice,
  pmiRate,
  futureValue = 0,
  type = 0,
) => {
  /*
     * ir   - interest rate per month
     * np   - number of periods (months)
     * pv   - present value
     * fv   - future value
     * type - when the payments are due:
     *        0: end of the period, e.g. end of month (default)
     *        1: beginning of period
     */

  if (!useLoan) return 0;
  let pmtVal;

  if (interestRate === 0) { return -(presentValue + futureValue) / numberOfPeriods; }

  const pvif = Math.pow(1 + interestRate, numberOfPeriods);
  pmtVal = (-interestRate * (presentValue * pvif + futureValue)) / (pvif - 1);

  if (type === 1) { pmtVal /= (1 + interestRate); }

  const pmiValue = pmi(useLoan, downPayment, purchasePrice, pmiRate);
  return ((pmtVal * -1) + (pmiValue / 12));
};

export const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
`;

export default substituteKeyWithCalculatedValueFromFormula;
