/* eslint-disable react/jsx-props-no-spreading */
import HelpIcon from '@mui/icons-material/Help';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import React from 'react';
import styled from 'styled-components';

export const InputFieldFieldContainer = styled.div`
    display: ${(props) => (props.shouldDisplay ? 'flex' : 'none')};
    background-color:  transparent;
    padding: 20px 40px 20px 40px;
    flex-direction: column;

    label {
        padding: 5px;
        color: black;
        font-weight: 300;
    }

    input {
        flex-basis: 100%;
    }
    .MuiFormGroup-root {
      justify-content: center ;
    }
`;

export const StyledTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    margin-bottom: 10px;

    svg {
        margin-left: 5px;
        align-self: center;
    }
`;

export const CustomizedTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 620,
    fontSize: '1.5rem',
    border: '1px solid #dadde9',
  },
}));

export const StyledHelpIcon = styled(HelpIcon)`
  cursor: pointer;
  `;
