import styled from 'styled-components';

export const PageContainer = styled.div`
    background-image: url("../images/PageNotFound.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;

    h1 {
      font-size: 15em;
      text-align: center;
      color: white;
    }
    a, p {
      color: white;
      text-align: center;
      font-size: 1.5em;
    }
`;
