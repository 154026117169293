import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  StyledAppBar, StyledLinked, StyledToolbar, StyledTypography,
} from './styles';

// Using Inline Styling
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
}));

// Exporting Default Navbar to the App.js File
export default function Navbar({ userLogin }) {
  const classes = useStyles();
  const [navigationTheme, setNavigationTheme] = useState('primary');
  const [isVisible, setVisibiity] = useState(true);
  const history = useLocation();

  useEffect(() => {
    const currentPath = history.pathname;
    if (currentPath === '/') {
      setVisibiity(false);
    } else if (currentPath === '/demo' && !userLogin.isVerified) {
      setNavigationTheme('primary');
    } else {
      setNavigationTheme('secondary');
    }
  }, [history, setNavigationTheme, userLogin, history.pathname]);

  return isVisible ? (
    <div className={classes.root}>
      <StyledAppBar position="fixed" $navigationTheme={navigationTheme}>
        <StyledToolbar variant="dense" $navigationTheme={navigationTheme}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <StyledLinked to="/" $navigationTheme={navigationTheme}>
              {' '}
              <HomeIcon />
              {' '}
            </StyledLinked>
          </IconButton>
          <StyledTypography variant="h6" $navigationTheme={navigationTheme}>
            Find My Next Investment
          </StyledTypography>
        </StyledToolbar>
      </StyledAppBar>
    </div>
  ) : null;
}

Navbar.propTypes = {
  userLogin: PropTypes.shape({ isVerified: PropTypes.bool }).isRequired,
};
